/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.title-row-box {
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  width: 100%;
  height: 87px;
  margin: 0;
  padding: 0;
  background: #f7f7f7;
}

.title-row-box > .component-content {
  max-width: 770px;
  margin: 0 auto !important;
}

.title-row-box > .component-content .title {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 23px 0 0 0;
  padding: 0;
}

.title-row-box > .component-content .title h1 {
  font-size: 22px;
  font-size: 2.2rem;
  border: none;
}

@media only screen and (max-width: 991px) {
  .title-row-box > .component-content .title {
    margin: 23px 0 0 20px;
  }
}

.title-row-box > .component-content .rich-text {
  margin: 0;
}

.title-row-box > .component-content .rich-text h1, .title-row-box > .component-content .rich-text h2, .title-row-box > .component-content .rich-text h3, .title-row-box > .component-content .rich-text h4 {
  margin: 0;
  padding: 0;
}

.title-row-box > .component-content .breadcrumb {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 14px;
  font-size: 1.4rem;
  margin: 23px 0 0 0;
  padding: 0;
  float: right;
  clear: both;
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  .title-row-box > .component-content .breadcrumb {
    margin: 23px 20px 0 0;
  }
}

@media only screen and (max-width: 576px) {
  .title-row-box > .component-content .breadcrumb {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .title-row-box .alpha {
    vertical-align: middle;
    box-sizing: border-box;
    width: auto !important;
    float: left;
  }
  .title-row-box .omega {
    vertical-align: middle;
    box-sizing: border-box;
    width: auto !important;
    float: right;
  }
}

.container {
  padding: 0;
  /*CONN Removed - see explanation at line 17 of component-navigation.scss*/
}

.container.container-fluid {
  max-width: 100% !important;
}

.container .component-content {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.container .component-content .accordion.items {
  padding-left: 0;
}
